import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { projetListAction } from '../../redux/actions/projetActions';
import './Projets.css'; // Assurez-vous d'avoir le bon chemin pour votre fichier CSS


import Modale from '../../components/Modale/Modale';

import { createMarkup } from '../../utils';

function DomiSimpli() {
    const dispatch = useDispatch();

    let successMessage = 'L\'application sera bientôt disponible sur les stores. Soyez patient et restez à l\'affût!';
    const [modaleState, setModaleState] = useState({ isOpen: false, type: 'info', message: successMessage });


    const projetList = useSelector((state) => state.projetList);
    const { projets, loading, error } = projetList;

    useEffect(() => {
        dispatch(projetListAction());
    }, [dispatch]);

    const openModale = (content) => {
        setModaleState({ ...modaleState, isOpen: true });
    };

    const handleCloseModale = () => {
        setModaleState({ ...modaleState, isOpen: false });
    }

    return (
        <div className="projets-container">
            <Helmet>
                <title>DomiSimpli, un projet ambitieux pour révolutionner le travail à domicile</title>
                <meta name="description" content="DomiSimpli" />
                <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store" />
            </Helmet>
            {loading && <p>Chargement...</p>}
            {error && <p>Erreur : {error}</p>}
            {projets && projets
                .filter(projet => projet.titre === "DomiSimpli")
                .map(projet => (
                    <>
                        <div className="projet-titre">
                            <img src={projet.image} className="projet-image" />
                            <div className="projet-media">
                                <div className="app-links">
                                    {projet.url_google_app && (
                                        // <a href={projet.url_google_app} target="_blank" rel="noopener noreferrer">
                                        <a onClick={() => openModale(<img src="http://localhost:3000/media/images/store/google-play.png" alt="Disponible sur Google Play" />)}>
                                            <img src="/media/images/store/google-play.png" alt="Disponible sur Google Play" />
                                        </a>
                                    )}
                                    {projet.url_apple_app && (
                                        // <a href={projet.url_apple_app} target="_blank" rel="noopener noreferrer">
                                        <a onClick={() => openModale(<img src="http://localhost:3000/media/images/store/apple-store.png" alt="Disponible sur l'App Store" />)}>
                                            <img src="/media/images/store/apple-store.png" alt="Disponible sur l'App Store" />
                                        </a>
                                    )}
                                </div>
                            </div>

                        </div >
                        <div key={projet.id} className="projet-card">
                            <div className="projet-info">
                                {projet.url_site_web && (
                                    <a href={projet.url_site_web} target="_blank" rel="noopener noreferrer" className="btn-visiter">Visiter le site</a>
                                )}
                                <p className='information-description' dangerouslySetInnerHTML={createMarkup(projet.description)}></p>
                            </div>

                        </div>
                    </>
                ))
            }
            <Modale
                isOpen={modaleState.isOpen}
                onClose={handleCloseModale}
                title={modaleState.type === 'add-success' ? 'Succès' : 'Erreur'}
                type={modaleState.type} // Passez le type ici
            >
                <p>{modaleState.message}</p>
            </Modale>
        </div >
    );
}

export default DomiSimpli;
