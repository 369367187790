import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Toggle from 'react-toggle';
import "react-toggle/style.css"; // pour les styles par défaut
import { parseISO, format } from 'date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';

import {
    informationListAction,
    informationAddAction,
    informationUpdateAction,
    informationDeleteAction,
    informationAddResetAction,
    informationUpdateResetAction,
    informationDeleteResetAction
} from '../../redux/actions/informationActions';
import {
    contactListAction,
    contactUpdateAction,
    contactDeleteAction,
    contactUpdateResetAction,
    contactDeleteResetAction
} from '../../redux/actions/contactActions';
import {
    candidatureListAction,
    candidatureUpdateAction,
    candidatureDeleteAction,
    candidatureUpdateResetAction,
    candidatureDeleteResetAction
} from '../../redux/actions/candidatureActions';
import {
    partenaireListAction,
    partenaireAddAction,
    partenaireUpdateAction,
    partenaireDeleteAction,
    partenaireAddResetAction,
    partenaireUpdateResetAction,
    partenaireDeleteResetAction
} from '../../redux/actions/partenaireActions';
import {
    projetListAction,
    projetAddAction,
    projetUpdateAction,
    projetDeleteAction,
    projetAddResetAction,
    projetUpdateResetAction,
    projetDeleteResetAction
} from '../../redux/actions/projetActions';

import RichTextEditor from '../../components/RichTextEditor/RichTextEditor'

import Modale from '../../components/Modale/Modale';
import './Administration.css';

function Administration() {
    const dispatch = useDispatch();

    const baseUrl = window.location.origin

    const informationList = useSelector((state) => state.informationList);
    const { informations, loading, error, success } = informationList;

    const informationAdd = useSelector((state) => state.informationAdd);
    const { loading: loadingAdd, error: errorAdd, success: successAdd } = informationAdd;

    const informationUpdate = useSelector((state) => state.informationUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = informationUpdate;

    const informationDelete = useSelector((state) => state.informationDelete);
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = informationDelete;

    const partenaireList = useSelector((state) => state.partenaireList);
    const { partenaires, loading: loadingPartenaires, error: errorPartenaires } = partenaireList;

    const partenaireAdd = useSelector((state) => state.partenaireAdd);
    const { loading: loadingPartenaireAdd, error: errorPartenaireAdd, success: successPartenaireAdd } = partenaireAdd;

    const partenaireUpdate = useSelector((state) => state.partenaireUpdate);
    const { loading: loadingPartenaire, error: errorPartenaire, success: successPartenaire } = partenaireUpdate;

    const partenaireDelete = useSelector((state) => state.partenaireDelete);
    const { loading: loadingPartenaireDelete, error: errorPartenaireDelete, success: successPartenaireDelete } = partenaireDelete;

    const projetList = useSelector((state) => state.projetList);
    const { projets, loading: loadingProjets, error: errorProjets } = projetList;

    const projetAdd = useSelector((state) => state.projetAdd);
    const { loading: loadingProjetAdd, error: errorProjetAdd, success: successProjetAdd } = projetAdd;

    const projetUpdate = useSelector((state) => state.projetUpdate);
    const { loading: loadingProjetUpdate, error: errorProjetUpdate, success: successProjetUpdate } = projetUpdate;

    const projetDelete = useSelector((state) => state.projetDelete);
    const { loading: loadingProjetDelete, error: errorProjetDelete, success: successProjetDelete } = projetDelete;

    const contactList = useSelector((state) => state.contactList);
    const { contacts, loading: contactLoading, error: contactError } = contactList;

    const contactUpdate = useSelector((state) => state.contactUpdate);
    const { loading: contactUpdateLoading, error: contactUpdateError, success: contactUpdateSuccess } = contactUpdate;

    const contactDelete = useSelector((state) => state.contactDelete);
    const { loading: contactDeleteLoading, error: contactDeleteError, success: contactDeleteSuccess } = contactDelete;

    const candidatureList = useSelector((state) => state.candidatureList);
    const { candidatures, loading: candidatureLoading, error: candidatureError } = candidatureList;

    const candidatureUpdate = useSelector((state) => state.candidatureUpdate);
    const { loading: candidatureUpdateLoading, error: candidatureUpdateError, success: candidatureUpdateSuccess } = candidatureUpdate;

    const candidatureDelete = useSelector((state) => state.candidatureDelete);
    const { loading: candidatureDeleteLoading, error: candidatureDeleteError, success: candidatureDeleteSuccess } = candidatureDelete;

    const [activeTab, setActiveTab] = useState('accueil');
    const [subActiveTab, setSubActiveTab] = useState('collaborateur');
    const [modaleState, setModaleState] = useState({ isOpen: false, type: '', message: '' });

    const [affichageForm, setAffichageForm] = useState(false);
    const [affichageImage, setAffichageImage] = useState(false);
    const [titre, setTitre] = useState('');
    const [texte, setTexte] = useState('');
    const [image, setImage] = useState(null);
    const [edit, setEdit] = useState(false);
    const [idEdit, setIdEdit] = useState(null);
    const [informationImage, setInformationImage] = useState(null);

    const [selectedCollaborateur, setSelectedCollaborateur] = useState(null);
    const [titreCollab, setTitreCollab] = useState('');
    const [texteCollab, setTexteCollab] = useState('');
    const [imageCollab, setImageCollab] = useState(null);
    const [affichageCollabForm, setAffichageCollabForm] = useState(false);
    const [collabEdit, setCollabEdit] = useState(false);
    const [idCollabEdit, setIdCollabEdit] = useState(null);

    const [selectedPartenaire, setSelectedPartenaire] = useState(null);
    const [nomPartenaire, setNomPartenaire] = useState('');
    const [titrePartenaire, setTitrePartenaire] = useState('');
    const [textePartenaire, setTextePartenaire] = useState('');
    const [imagePartenaire, setImagePartenaire] = useState(null);
    const [affichagePartenaireForm, setAffichagePartenaireForm] = useState(false);
    const [partenaireEdit, setPartenaireEdit] = useState(false);
    const [idPartenaireEdit, setIdPartenaireEdit] = useState(null);
    const [url_site_web_partenaire, setUrlSiteWebPartenaire] = useState('');

    const [selectedProjet, setSelectedProjet] = useState(null);
    const [titreProjet, setTitreProjet] = useState('');
    const [texteProjet, setTexteProjet] = useState('');
    const [url_google_app, setUrlGoogleApp] = useState('');
    const [url_apple_app, setUrlAppleApp] = useState('');
    const [url_site_web, setUrlSiteWeb] = useState('');
    const [imageProjet, setImageProjet] = useState(null);
    const [affichageProjetForm, setAffichageProjetForm] = useState(false);
    const [projetEdit, setProjetEdit] = useState(false);
    const [idProjetEdit, setIdProjetEdit] = useState(null);


    const [selectedValeur, setSelectedValeur] = useState(null);
    const [titreValeur, setTitreValeur] = useState('');
    const [texteValeur, setTexteValeur] = useState('');
    const [imageValeur, setImageValeur] = useState(null);
    const [affichageValeurForm, setAffichageValeurForm] = useState(false);
    const [valeurEdit, setValeurEdit] = useState(false);
    const [idValeurEdit, setIdValeurEdit] = useState(null);

    const [informationAccueil, setInformationAccueil] = useState(null);
    const [informationCollaborateurs, setInformationCollaborateurs] = useState(null);
    const [informationValeurs, setInformationValeurs] = useState(null);

    const [showAnswerForm, setShowAnswerForm] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [reponseTexte, setReponseTexte] = useState('');

    const toggleShowResponded = () => setShowResponded(!showResponded);
    const [showResponded, setShowResponded] = useState(false);

    const informationAddSuccessMessage = 'L\'information a été ajoutée avec succès!';
    const informationAddErrorMessage = 'Une erreur s\'est produite lors de l\'ajout de l\'information. Veuillez réessayer.';
    const informationUpdateSuccessMessage = 'L\'information a été modifiée avec succès!';
    const informationUpdateErrorMessage = 'Une erreur s\'est produite lors de la modification de l\'information. Veuillez réessayer.';
    const informationDeleteSuccessMessage = 'L\'information a été supprimée avec succès!';
    const informationDeleteErrorMessage = 'Une erreur s\'est produite lors de la suppression de l\'information. Veuillez réessayer.';
    const contactUpdateSuccessMessage = 'Le contact a été mis à jour avec succès!';
    const contactUpdateErrorMessage = 'Une erreur s\'est produite lors de la mise à jour du contact. Veuillez réessayer.';
    const contactDeleteSuccessMessage = 'Le contact a été supprimé avec succès!';
    const contactDeleteErrorMessage = 'Une erreur s\'est produite lors de la suppression du contact. Veuillez réessayer.';
    const candidatureUpdateSuccessMessage = 'La candidature a été mise à jour avec succès!';
    const candidatureUpdateErrorMessage = 'Une erreur s\'est produite lors de la mise à jour de la candidature. Veuillez réessayer.';
    const candidatureDeleteSuccessMessage = 'La candidature a été supprimée avec succès!';
    const candidatureDeleteErrorMessage = 'Une erreur s\'est produite lors de la suppression de la candidature. Veuillez réessayer.';
    const partenaireUpdateSuccessMessage = 'Le partenaire a été mis à jour avec succès!';
    const partenaireUpdateErrorMessage = 'Une erreur s\'est produite lors de la mise à jour du partenaire. Veuillez réessayer.';
    const partenaireDeleteSuccessMessage = 'Le partenaire a été supprimé avec succès!';
    const partenaireDeleteErrorMessage = 'Une erreur s\'est produite lors de la suppression du partenaire. Veuillez réessayer.';
    const projetAddSuccessMessage = 'Le projet a été ajouté avec succès!';
    const projetAddErrorMessage = 'Une erreur s\'est produite lors de l\'ajout du projet. Veuillez réessayer.';
    const projetUpdateSuccessMessage = 'Le projet a été mis à jour avec succès!';
    const projetUpdateErrorMessage = 'Une erreur s\'est produite lors de la mise à jour du projet. Veuillez réessayer.';


    useEffect(() => {
        dispatch(informationListAction());
        dispatch(projetListAction());
        dispatch(partenaireListAction());
        dispatch(contactListAction());
        dispatch(candidatureListAction());
    }, [dispatch]);

    useEffect(() => {
        if (informations && informations.length > 0) {
            setInformationAccueil(informations.filter((information) => information.type === "Accueil"));
            setInformationCollaborateurs(informations
                .filter(information => information.type === "Collaborateur")
                .sort((a, b) => a.titre.localeCompare(b.titre)));
            setInformationValeurs(informations.filter((information) => information.type === "Valeurs"));
        }
    }, [informations]);

    useEffect(() => {
        if (successAdd) {
            console.log('successAdd', successAdd);
            setModaleState({ isOpen: true, type: 'success', message: informationAddSuccessMessage });
            dispatch(informationListAction());
            setAffichageCollabForm(false);
            setAffichageForm(false);
        } else if (errorAdd) {
            setModaleState({ isOpen: true, type: 'error', message: informationAddErrorMessage });
        }
        dispatch(informationAddResetAction());
    }, [successAdd, errorAdd, dispatch]);

    useEffect(() => {
        if (successUpdate) {
            setModaleState({ isOpen: true, type: 'success', message: informationUpdateSuccessMessage });
            dispatch(informationListAction());
            setAffichageCollabForm(false);
            setAffichageForm(false);
        } else if (errorUpdate) {
            setModaleState({ isOpen: true, type: 'error', message: informationUpdateErrorMessage });
        }
        dispatch(informationUpdateResetAction());
    }, [successUpdate, errorUpdate, dispatch]);

    useEffect(() => {
        if (successPartenaireAdd) {
            setModaleState({ isOpen: true, type: 'success', message: informationAddSuccessMessage });
            dispatch(partenaireListAction());
            setAffichagePartenaireForm(false);
        } else if (errorPartenaireAdd) {
            setModaleState({ isOpen: true, type: 'error', message: informationAddErrorMessage });
        }
        dispatch(partenaireAddResetAction());
    }, [successPartenaireAdd, errorPartenaireAdd, dispatch]);

    useEffect(() => {
        if (successPartenaire) {
            setModaleState({ isOpen: true, type: 'success', message: partenaireUpdateSuccessMessage });
            dispatch(partenaireListAction());
            setAffichagePartenaireForm(false);
        } else if (errorPartenaire) {
            setModaleState({ isOpen: true, type: 'error', message: partenaireUpdateErrorMessage });
        }
        dispatch(partenaireUpdateResetAction());
    }, [successPartenaire, errorPartenaire, dispatch]);

    useEffect(() => {
        if (successPartenaireDelete) {
            setModaleState({ isOpen: true, type: 'success', message: partenaireDeleteSuccessMessage });
            dispatch(partenaireListAction());
        } else if (errorPartenaireDelete) {
            setModaleState({ isOpen: true, type: 'error', message: partenaireDeleteErrorMessage });
        }
        dispatch(partenaireDeleteResetAction());
    }, [successPartenaireDelete, errorPartenaireDelete, dispatch]);

    useEffect(() => {
        if (successProjetAdd) {
            setModaleState({ isOpen: true, type: 'success', message: projetAddSuccessMessage });
            dispatch(projetListAction());
        } else if (errorProjetAdd) {
            setModaleState({ isOpen: true, type: 'error', message: projetAddErrorMessage });
        }
        dispatch(projetAddResetAction());
    }, [successProjetAdd, errorProjetAdd, dispatch]);

    useEffect(() => {
        if (successProjetUpdate) {
            setModaleState({ isOpen: true, type: 'success', message: projetUpdateSuccessMessage });
            dispatch(projetListAction());
        } else if (errorProjetUpdate) {
            setModaleState({ isOpen: true, type: 'error', message: projetUpdateErrorMessage });
        }
        dispatch(projetUpdateResetAction());
    }, [successProjetUpdate, errorProjetUpdate, dispatch]);

    useEffect(() => {
        if (successProjetDelete) {
            setModaleState({ isOpen: true, type: 'success', message: informationDeleteSuccessMessage });
            dispatch(projetListAction());
        } else if (errorProjetDelete) {
            setModaleState({ isOpen: true, type: 'error', message: informationDeleteErrorMessage });
        }
        dispatch(projetDeleteResetAction());
    }, [successProjetDelete, errorProjetDelete, dispatch]);

    useEffect(() => {
        if (contactUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'success', message: contactUpdateSuccessMessage });
            dispatch(contactListAction());
        } else if (contactUpdateError) {
            setModaleState({ isOpen: true, type: 'error', message: contactUpdateErrorMessage });
        }
        dispatch(contactUpdateResetAction());
    }, [contactUpdateSuccess, contactUpdateError, dispatch]);

    useEffect(() => {
        if (contactDeleteSuccess) {
            setModaleState({ isOpen: true, type: 'success', message: contactDeleteSuccessMessage });
            dispatch(contactListAction());
        } else if (contactDeleteError) {
            setModaleState({ isOpen: true, type: 'error', message: contactDeleteErrorMessage });
        }
        dispatch(contactDeleteResetAction());
    }, [contactDeleteSuccess, contactDeleteError, dispatch]);

    useEffect(() => {
        if (candidatureUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'success', message: candidatureUpdateSuccessMessage });
            dispatch(candidatureListAction());
        } else if (candidatureUpdateError) {
            setModaleState({ isOpen: true, type: 'error', message: candidatureUpdateErrorMessage });
        }
        dispatch(candidatureUpdateResetAction());
    }, [candidatureUpdateSuccess, candidatureUpdateError, dispatch]);

    useEffect(() => {
        if (candidatureDeleteSuccess) {
            setModaleState({ isOpen: true, type: 'success', message: candidatureDeleteSuccessMessage });
            dispatch(candidatureListAction());
        } else if (candidatureDeleteError) {
            setModaleState({ isOpen: true, type: 'error', message: candidatureDeleteErrorMessage });
        }
        dispatch(candidatureDeleteResetAction());
    }, [candidatureDeleteSuccess, candidatureDeleteError, dispatch]);

    useEffect(() => {
        if (successPartenaireDelete) {
            setModaleState({ isOpen: true, type: 'success', message: partenaireDeleteSuccessMessage });
            dispatch(partenaireListAction());
        } else if (errorPartenaireDelete) {
            setModaleState({ isOpen: true, type: 'error', message: partenaireDeleteErrorMessage });
        }
        dispatch(partenaireDeleteResetAction());
    }, [successPartenaireDelete, errorPartenaireDelete, dispatch]);

    useEffect(() => {
        if (successDelete) {
            setModaleState({ isOpen: true, type: 'success', message: informationDeleteSuccessMessage });
            dispatch(informationListAction());
        } else if (errorDelete) {
            setModaleState({ isOpen: true, type: 'error', message: informationDeleteErrorMessage });
        }
        dispatch(informationDeleteResetAction());
    }, [successDelete, errorDelete, dispatch]);


    const handleCloseModale = () => {
        setModaleState({ ...modaleState, isOpen: false });
    }

    const renderAccueil = () => {
        const handleImageChange = (e) => {
            if (e.target.files[0]) {
                setImage(e.target.files[0]);
            }
        };

        const handleSubmit = (e) => {
            e.preventDefault();

            const informationFormData = new FormData();
            informationFormData.append('titre', titre);
            informationFormData.append('description', texte);
            informationFormData.append('display', affichageImage);
            informationFormData.append('type', 'Accueil');


            if (image) {
                informationFormData.append('image', image)
            } else if (edit)
                informationFormData.append('image', informationImage);

            if (edit) {
                // Supposons que vous avez une logique pour gérer l'édition
                dispatch(informationUpdateAction(idEdit, informationFormData));
            } else {
                dispatch(informationAddAction(informationFormData));
            }
        };

        const handleEdit = (id) => {
            console.log("Édition de l'information avec l'id :", id);
            // Ici, ouvrez un formulaire d'édition ou dispatchez une action Redux
            let information = informations.find(info => info.id === id);
            setEdit(true);
            setIdEdit(id);
            setInformationImage(information.image.replace("media/", ""));
            setAffichageForm(true);
            setAffichageImage(information.display);
            setTitre(information.titre);
            setTexte(information.description);
        };

        const handleDelete = (id) => {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette information ?");

            // Si l'utilisateur confirme, procède à la suppression
            if (isConfirmed) {
                setEdit(false);
                // Ici, confirmez la suppression puis dispatchez une action Redux ou faites une requête API pour supprimer
                dispatch(informationDeleteAction(id))
            }
        };

        const handleForm = () => {
            setEdit(!edit);
            setAffichageForm(!affichageForm);

            if (!affichageForm) {
                setTitre('');
                setTexte('');
                setImage(null);
            }
        }

        const handleEditorChange = (value) => {
            setTexte(value); // Traitez ou enregistrez la valeur ici
        }

        return (
            <>
                <div className="liste-info">
                    <h2>Liste des Informations</h2>
                    {informationAccueil && informationAccueil.map((info) => (
                        <div key={info.id} className="info-item">
                            <p>{info.titre}</p>
                            <div key={info.id} className="info-item-button">
                                <button className="edit-button" onClick={() => handleEdit(info.id)}>Éditer</button>
                                <button className="delete-button" onClick={() => handleDelete(info.id)}>Supprimer</button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="info-add-button">
                    <button className={!affichageForm ? "add-button" : "cancel-button"}
                        onClick={() => handleForm()}>
                        {!affichageForm ? "Nouveau" : "Annuler"}</button>
                </div>
                {
                    affichageForm && (
                        <div>
                            <h1>Administration - Accueil</h1>
                            <div className="affichage-data-input">
                                <label htmlFor="affichageInfo">Aficher l'information :</label>
                                <input
                                    id="affichageInfo"
                                    type="checkbox"
                                    checked={affichageImage}
                                    onChange={(e) => setAffichageImage(e.target.checked)}
                                />
                            </div>
                            <form onSubmit={handleSubmit} className="admin-form">
                                <input type="text" placeholder="Titre" value={titre}
                                    onChange={(e) => setTitre(e.target.value)} />
                                <div className='text-editor'>
                                    <RichTextEditor value={texte != undefined ? texte : ''} onChange={handleEditorChange} />
                                </div>
                                <input type="file" onChange={handleImageChange} />
                                <button type="submit">Enregistrer</button>
                            </form>
                        </div>
                    )
                }

            </>
        );
    }

    const renderCoodent = () => {

        const renderCollaborateur = () => {
            const handleCardClick = (information) => {
                setSelectedCollaborateur(information);
                setCollabEdit(true);
                setIdCollabEdit(information.id);
                setAffichageCollabForm(true);
                setTitreCollab(information.titre);
                setTexteCollab(information.description);
            }

            const handleImageChange = (e) => {
                if (e.target.files[0]) {
                    alert(e.target.files[0]);
                    setImageCollab(e.target.files[0]);
                }
            }

            const handleSubmit = (e) => {
                e.preventDefault();

                const informationFormData = new FormData();
                informationFormData.append('titre', titreCollab);
                informationFormData.append('description', texteCollab);
                informationFormData.append('type', 'Collaborateur');
                informationFormData.append('display', true);

                console.log('imageCollab', imageCollab);
                if (imageCollab)
                    informationFormData.append('image', imageCollab)


                if (collabEdit) {
                    // Supposons que vous avez une logique pour gérer l'édition
                    dispatch(informationUpdateAction(idCollabEdit, informationFormData));
                    setCollabEdit(false);
                    setIdCollabEdit(null);
                } else {
                    dispatch(informationAddAction(informationFormData));
                }
            };

            const handleEditorChange = (value) => {
                setTexteCollab(value); // Traitez ou enregistrez la valeur ici
            }

            const handleCollabForm = () => {
                setCollabEdit(!collabEdit);
                setAffichageCollabForm(!affichageCollabForm);
                if (!affichageCollabForm) {
                    setSelectedCollaborateur(null);
                    setTitreCollab('');
                    setTexteCollab('');
                    setImageCollab(null);
                    setIdCollabEdit(null);
                    setCollabEdit(false);
                }
            }

            const handleDelete = (id) => {
                const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce collaborateur ?");

                // Si l'utilisateur confirme, procède à la suppression
                if (isConfirmed) {
                    setCollabEdit(false);
                    // Ici, confirmez la suppression puis dispatchez une action Redux ou faites une requête API pour supprimer
                    dispatch(informationDeleteAction(id))
                }
            }

            return (
                <div className="administration-collaborateurs">
                    <div className="collaborateur-cards-container">
                        {informationCollaborateurs.map(information => (
                            <div
                                key={information._id}
                                className={`collaborateur-card ${selectedCollaborateur === information ? 'selected' : ''}`}
                                onClick={() => handleCardClick(information)}
                            >
                                <img src={information.image} alt={information.titre} className="collaborateur-image" />
                                <h3>{information.titre}</h3>
                                <button className="delete-button" onClick={() => handleDelete(information.id)}>Supprimer</button>
                            </div>
                        ))}
                    </div>
                    <div className="info-add-affichageForm">
                        <button className={!affichageCollabForm ? "add-button" : "cancel-button"}
                            onClick={() => handleCollabForm()}>
                            {!affichageCollabForm ? "Nouveau" : "Annuler"}</button>
                    </div>
                    {(affichageCollabForm) && (
                        <form onSubmit={handleSubmit} className="admin-form">
                            <input type="text" placeholder="Titre" value={titreCollab} onChange={(e) => setTitreCollab(e.target.value)} />
                            <div className='text-editor'>
                                <RichTextEditor value={texteCollab !== undefined ? texteCollab : ''} onChange={handleEditorChange} />
                            </div>
                            <input type="file" onChange={handleImageChange} />
                            <button type="submit">Enregistrer</button>
                        </form>
                    )}
                </div>
            );
        }

        const renderPartenaires = () => {
            const handleCardClick = (partenaire) => {
                setPartenaireEdit(true);
                setIdPartenaireEdit(partenaire.id);
                setSelectedPartenaire(partenaire);
                setNomPartenaire(partenaire.nom);
                setTitrePartenaire(partenaire.titre);
                setTextePartenaire(partenaire.description);
                setUrlSiteWebPartenaire(partenaire.url_site_web);
                setAffichagePartenaireForm(true);
            }

            const handleImageChange = (e) => {
                if (e.target.files[0]) {
                    setImagePartenaire(e.target.files[0]);
                }
            }

            const handleSubmit = (e) => {
                e.preventDefault();

                const partenaireFormData = new FormData();
                partenaireFormData.append('nom', nomPartenaire);
                partenaireFormData.append('titre', titrePartenaire);
                partenaireFormData.append('description', textePartenaire);
                partenaireFormData.append('url_site_web', url_site_web_partenaire);

                if (image) {
                    partenaireFormData.append('image', image)
                } else if (partenaireEdit)
                    partenaireFormData.append('image', informationImage);

                if (partenaireEdit) {
                    // Supposons que vous avez une logique pour gérer l'édition
                    dispatch(partenaireUpdateAction(idPartenaireEdit, partenaireFormData));
                    setPartenaireEdit(false);
                    setIdPartenaireEdit(null);
                } else {
                    dispatch(partenaireAddAction(partenaireFormData));
                }
            };

            const handleEditorChange = (value) => {
                setTextePartenaire(value); // Traitez ou enregistrez la valeur ici
            }

            const handlePartenaireForm = () => {
                setAffichagePartenaireForm(!affichagePartenaireForm);
                if (!affichagePartenaireForm) {
                    setSelectedPartenaire(null);
                    setNomPartenaire('');
                    setTitrePartenaire('');
                    setTextePartenaire('');
                    setUrlSiteWebPartenaire('');
                    setImagePartenaire(null);
                    setPartenaireEdit(false);
                }
            }

            const handleDelete = (id) => {
                const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce partenaire ?");
                if (isConfirmed) {
                    setPartenaireEdit(false);
                    dispatch(partenaireDeleteAction(id))
                }
            }

            return (
                <div className="administration-partenaires">
                    <div className="partenaires-cards-container">
                        {partenaires.map(partenaire => (
                            <div
                                key={partenaire._id}
                                className={`partenaires-card ${selectedPartenaire === partenaire ? 'selected' : ''}`}
                                onClick={() => handleCardClick(partenaire)}
                            >
                                <img src={partenaire.image} alt={partenaire.titre} className="partenaires-image" />
                                <h3>{partenaire.titre}</h3>
                                <button className="delete-button" onClick={() => handleDelete(partenaire.id)}>Supprimer</button>
                            </div>
                        ))}
                    </div>
                    <div className="info-add-affichageForm">
                        <button className={!affichagePartenaireForm ? "add-button" : "cancel-button"}
                            onClick={() => handlePartenaireForm()}>
                            {!affichagePartenaireForm ? "Nouveau" : "Annuler"}</button>
                    </div>
                    {(affichagePartenaireForm) && (
                        <form onSubmit={handleSubmit} className="admin-form">
                            <input type="text" placeholder="Nom" value={nomPartenaire} onChange={(e) => setNomPartenaire(e.target.value)} />
                            <input type="text" placeholder="Titre" value={titrePartenaire} onChange={(e) => setTitrePartenaire(e.target.value)} />
                            <input type="text" placeholder="url site web" value={url_site_web_partenaire} onChange={(e) => setUrlSiteWebPartenaire(e.target.value)} />
                            {
                                url_google_app || url_apple_app ? (
                                    <>
                                        <input type="text" placeholder="url google" value={url_google_app} onChange={(e) => setUrlGoogleApp(e.target.value)} />
                                        <input type="text" placeholder="url apple" value={url_apple_app} onChange={(e) => setUrlAppleApp(e.target.value)} />
                                    </>
                                ) : null
                            }
                            <div className='text-editor'>
                                <RichTextEditor value={textePartenaire !== undefined ? textePartenaire : ''} onChange={handleEditorChange} />
                            </div>
                            <input type="file" onChange={handleImageChange} />
                            <button type="submit">Enregistrer</button>
                        </form>
                    )}
                </div>
            );
        }

        const renderProjets = () => {
            const handleCardClick = (projet) => {
                setSelectedProjet(projet);
                setAffichageProjetForm(true);
                setTitreProjet(projet.titre);
                setTexteProjet(projet.description);
                setUrlGoogleApp(projet.url_google_app);
                setUrlAppleApp(projet.url_apple_app);
                setUrlSiteWeb(projet.url_site_web);
                setIdProjetEdit(projet.id);
                setProjetEdit(true);
            }

            const handleImageChange = (e) => {
                if (e.target.files[0]) {
                    setImageProjet(e.target.files[0]);
                }
            }

            const handleSubmit = (e) => {
                e.preventDefault();

                const projetFormData = new FormData();
                projetFormData.append('titre', titreProjet);
                projetFormData.append('description', texteProjet);
                projetFormData.append('url_site_web', url_site_web);
                projetFormData.append('url_google_app', url_google_app);
                projetFormData.append('url_apple_app', url_apple_app);

                projetFormData.append('image', imageProjet);

                if (projetEdit) {
                    // Supposons que vous avez une logique pour gérer l'édition
                    dispatch(projetUpdateAction(idProjetEdit, projetFormData));
                    setEdit(false);
                    setIdEdit(null);
                } else {
                    dispatch(projetAddAction(projetFormData));
                }
            };

            const handleEditorChange = (value) => {
                setTexteProjet(value); // Traitez ou enregistrez la valeur ici
            }

            const handleProjetForm = () => {
                setProjetEdit(!valeurEdit);
                setAffichageProjetForm(!affichageProjetForm);
                if (!affichageProjetForm) {
                    setSelectedProjet(null);
                    setTitreProjet('');
                    setTexteProjet('');
                    setImageProjet(null);
                    setUrlAppleApp('');
                    setUrlGoogleApp('');
                    setUrlSiteWeb('');
                    setIdProjetEdit(null);
                    setProjetEdit(false);
                }
            }

            const handleDelete = (id) => {
                const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce projet ?");
                if (isConfirmed) {
                    setProjetEdit(false);
                    dispatch(projetDeleteAction(id))
                }
            }

            return (
                <div className="administration-projets">
                    <div className="projets-cards-container">
                        {projets.map(projet => (
                            <div
                                key={projet._id}
                                className={`projets-card ${selectedProjet === projet ? 'selected' : ''}`}
                                onClick={() => handleCardClick(projet)}
                            >
                                <img src={projet.image} alt={projet.titre} className="projets-image" />
                                <h3>{projet.titre}</h3>
                                <button className="delete-button" onClick={() => handleDelete(projet.id)}>Supprimer</button>
                            </div>
                        ))}
                    </div>
                    <div className="info-add-affichageForm">
                        <button className={!affichageProjetForm ? "add-button" : "cancel-button"}
                            onClick={() => handleProjetForm()}>
                            {!affichageProjetForm ? "Nouveau" : "Annuler"}</button>
                    </div>
                    {affichageProjetForm && (
                        <form onSubmit={handleSubmit} className="admin-form">
                            <input type="text" placeholder="Titre" value={titreProjet} onChange={(e) => setTitreProjet(e.target.value)} />
                            {
                                url_google_app || url_apple_app ? (
                                    <>
                                        <input type="text" placeholder="url google" value={url_google_app} onChange={(e) => setUrlGoogleApp(e.target.value)} />
                                        <input type="text" placeholder="url apple" value={url_apple_app} onChange={(e) => setUrlAppleApp(e.target.value)} />
                                        <input type="text" placeholder="url site web" value={url_site_web} onChange={(e) => setUrlSiteWeb(e.target.value)} />
                                    </>
                                ) : null
                            }

                            <div className='text-editor'>
                                <RichTextEditor value={texteProjet !== undefined ? texteProjet : ''} onChange={handleEditorChange} />
                            </div>
                            <input type="file" onChange={handleImageChange} />
                            <button type="submit">Enregistrer</button>
                        </form>
                    )}
                </div>
            );
        }

        const renderValeurs = () => {
            const handleCardClick = (valeurs) => {
                setSelectedValeur(valeurs);
                setValeurEdit(true);
                setIdValeurEdit(valeurs.id);
                setAffichageValeurForm(true);
                setTitreValeur(valeurs.titre);
                setTexteValeur(valeurs.description);
            }

            const handleImageChange = (e) => {
                if (e.target.files[0]) {
                    setImageValeur(e.target.files[0]);
                }
            }

            const handleSubmit = (e) => {
                e.preventDefault();

                const informationFormData = new FormData();
                informationFormData.append('titre', titreValeur);
                informationFormData.append('description', texteValeur);
                informationFormData.append('type', 'Valeurs');
                informationFormData.append('display', true);
                informationFormData.append('image', imageValeur);

                if (valeurEdit) {
                    // Supposons que vous avez une logique pour gérer l'édition
                    dispatch(informationUpdateAction(idValeurEdit, informationFormData));
                    setValeurEdit(false);
                    setIdValeurEdit(null);
                } else {
                    dispatch(informationAddAction(informationFormData));
                }

                setAffichageValeurForm(false);
            };

            const handleEditorChange = (value) => {
                setTexteValeur(value); // Traitez ou enregistrez la valeur ici
            }

            const handleValeurForm = () => {
                setValeurEdit(!valeurEdit);
                setAffichageValeurForm(!affichageValeurForm);
                if (!affichageProjetForm) {
                    setSelectedValeur(null);
                    setTitreValeur('');
                    setTexteValeur('');
                    setImageValeur(null);
                    setIdValeurEdit(null);
                    setValeurEdit(false);
                }
            }

            const handleDelete = (id) => {
                const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette valeur ?");
                if (isConfirmed) {
                    setValeurEdit(false);
                    dispatch(informationDeleteAction(id))
                }
            }

            return (
                <div className="administration-valeurs">
                    <div className="valeur-cards-container">
                        {informationValeurs.map(information => (
                            <>
                                <div
                                    key={information._id}
                                    className={`admin-valeur-card ${selectedValeur === information ? 'selected' : ''}`}
                                    onClick={() => handleCardClick(information)}
                                >
                                    <img src={information.image} alt={information.titre} className="valeur-image" />
                                    <h3>{information.titre}</h3>
                                </div>
                                <button className="admin-delete-button" onClick={() => handleDelete(information.id)}>Supprimer</button>
                            </>
                        ))}
                    </div>
                    <div className="info-add-affichageForm">
                        <button className={!affichageValeurForm ? "add-button" : "cancel-button"}
                            onClick={() => handleValeurForm()}>
                            {!affichageValeurForm ? "Nouveau" : "Annuler"}</button>
                    </div>
                    {affichageValeurForm && (
                        <form onSubmit={handleSubmit} className="admin-form">
                            <input type="text" placeholder="Titre" value={titreValeur} onChange={(e) => setTitreValeur(e.target.value)} />
                            <div className='text-editor'>
                                <RichTextEditor value={texteValeur !== undefined ? texteValeur : ''} onChange={handleEditorChange} />
                            </div>
                            <input type="file" onChange={handleImageChange} />
                            <button type="submit">Enregistrer</button>
                        </form>
                    )}
                </div>
            );
        }

        return (
            <div className="administration-coodent">
                <div className="sub-tabs">
                    <button className={subActiveTab === 'collaborateur' ? 'subbar-active' : 'bar-normal'}
                        onClick={() => setSubActiveTab('collaborateur')}>Qui sommes nous ?
                    </button>
                    <button className={subActiveTab === 'partenaires' ? 'subbar-active' : 'bar-normal'}
                        onClick={() => setSubActiveTab('partenaires')}>Nos partenaires
                    </button>
                    <button className={subActiveTab === 'projets' ? 'subbar-active' : 'bar-normal'}
                        onClick={() => setSubActiveTab('projets')}>Nos projets
                    </button>
                    <button className={subActiveTab === 'valeurs' ? 'subbar-active' : 'bar-normal'}
                        onClick={() => setSubActiveTab('valeurs')}>Nos Valeurs
                        {/* {
                                    contacts && contacts.length > 0 ? (
                                        <span className="contact-badge">{contacts.filter(c => c.repondu === false).length}</span>
                                    ) : null
                                } */}
                    </button>
                </div>
                <div className="sub-tab-content">
                    {subActiveTab === 'collaborateur' && renderCollaborateur()}
                    {subActiveTab === 'partenaires' && renderPartenaires()}
                    {subActiveTab === 'projets' && renderProjets()}
                    {subActiveTab === 'valeurs' && renderValeurs()}
                </div>
            </div>
        );
    }

    const renderNousRejoindre = () => {
        const handleResponse = (candidatureId) => {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir marquer cette demande comme répondue ?");

            // Si l'utilisateur confirme, procède à la suppression
            if (isConfirmed) {
                let candidature = {
                    ...candidatures.find(c => c.id === candidatureId),
                    repondu: true
                }
                dispatch(candidatureUpdateAction(candidatureId, candidature));
            }
        }

        const handleDeleteContact = (candidatureId) => {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette demande ?");

            // Si l'utilisateur confirme, procède à la suppression
            if (isConfirmed) {
                dispatch(candidatureDeleteAction(candidatureId));
            }
        }

        function readableDate(dateString) {
            const date = parseISO(dateString);
            const formattedDate = format(date, 'dd/MM/yyyy à HH:mm:ss');
            return formattedDate;
        }

        return (
            <div className="liste-info">
                <Helmet>
                    <title>Administration - Candidatures</title>
                </Helmet>
                <div className="contact-list-container">
                    <h2 className="contact-list-title">Liste des candidatures</h2>
                    <div className="contact-list-toggle">
                        <span style={{ marginRight: '10px' }}>{showResponded ? "Afficher non répondues" : "Afficher répondues"}</span>
                        <Toggle
                            defaultChecked={showResponded}
                            icons={false}
                            onChange={() => setShowResponded(!showResponded)} />
                    </div>
                    {
                        candidatures && candidatures.length > 0 ? (
                            <div className="contact-list">
                                {candidatures.filter(candidature => candidature.repondu === showResponded).map(candidature => (
                                    <div key={candidature.id} className="contact-item">
                                        <div className="contact-info">
                                            <p className="contact-date">le {readableDate(candidature.created_at)}</p>
                                            <p className="contact-subject">{candidature.type_poste}</p>
                                            <p className="contact-name">{candidature.civilite} {candidature.prenom} {candidature.nom}</p>
                                            <p className="contact-email">{candidature.email}</p>
                                            <p className="contact-phone">{candidature.telephone}</p>
                                            <p className="contact-message">{candidature.texte}</p>
                                            {
                                                candidature.document &&
                                                <a href={`${baseUrl}${candidature.document}`} target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faFilePdf} className='pdfButton' size="2x" />
                                                </a>}
                                        </div>
                                        <div className="contact-actions">
                                            <a href={`mailto:${candidature.email}?subject=Réponse à votre demande&body=Bonjour ${candidature.prenom},%0D%0A%0D%0AVotre demande a été reçue.%0D%0A%0D%0A`} className="admin-reply-button">Répondre</a>
                                            <button className="admin-mark-as-responded-button" onClick={() => handleResponse(candidature.id)}>Marquer comme répondu</button>
                                            <button className="admin-delete-button" onClick={() => handleDeleteContact(candidature.id)}>Supprimer</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="no-candidatures">
                                <h2>Pas de candidatures</h2>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }

    const renderContact = () => {
        const handleResponse = (contactId) => {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir marquer cette demande comme répondue ?");

            // Si l'utilisateur confirme, procède à la suppression
            if (isConfirmed) {
                let contact = {
                    ...contacts.find(c => c.id === contactId),
                    repondu: true
                }
                dispatch(contactUpdateAction(contactId, contact));
            }
        }

        const handleDeleteContact = (contactId) => {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette demande ?");

            // Si l'utilisateur confirme, procède à la suppression
            if (isConfirmed) {
                dispatch(contactDeleteAction(contactId));
            }
        }

        return (
            <div className="liste-info">
                <Helmet>
                    <title>Administration - Contact</title>
                </Helmet>
                <div className="contact-list-container">
                    <h2 className="contact-list-title">Liste des contacts</h2>
                    <div className="contact-list-toggle">
                        <span style={{ marginRight: '10px' }}>{showResponded ? "Afficher non répondues" : "Afficher répondues"}</span>
                        <Toggle
                            defaultChecked={showResponded}
                            icons={false}
                            onChange={() => setShowResponded(!showResponded)} />
                    </div>
                    {
                        contacts && contacts.length > 0 ? (
                            <div className="contact-list">
                                {contacts.filter(contact => contact.repondu === showResponded).map(contact => (
                                    <div key={contact.id} className="contact-item">
                                        <div className="contact-info">
                                            <p className="contact-name">{contact.civilite} {contact.prenom} {contact.nom}</p>
                                            {
                                                contact.siret && contact.siret !== '' &&
                                                <p className="contact-siret">SIRET : {contact.siret}</p>
                                            }
                                            <p className="contact-email">{contact.email}</p>
                                            <p className="contact-phone">{contact.telephone}</p>
                                            <p className="contact-subject">{contact.sujet}</p>
                                            <p className="contact-message">{contact.message}</p>
                                            {
                                                contact.document &&
                                                <a href={`${baseUrl}${contact.document}`} target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faFilePdf} className='pdfButton' size="2x" />
                                                </a>
                                            }
                                        </div>
                                        <div className="contact-actions">
                                            <a href={`mailto:${contact.email}?subject=Réponse à votre demande&body=Bonjour ${contact.prenom},%0D%0A%0D%0AVotre demande a été reçue.%0D%0A%0D%0A`} className="admin-reply-button">Répondre</a>
                                            <button className="admin-mark-as-responded-button" onClick={() => handleResponse(contact.id)}>Marquer comme répondu</button>
                                            <button className="admin-delete-button" onClick={() => handleDeleteContact(contact.id)}>Supprimer</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="no-contacts">
                                <h2>Pas de contacts</h2>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="administration">
            <Helmet>
                <title>Administration</title>
            </Helmet>
            {
                false ? (
                    <div>Chargement...</div>
                ) : false ? (
                    <div>{'error'}</div>
                ) : (
                    <div className="administration">
                        <div className="tabs">
                            <button className={activeTab === 'accueil' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('accueil')}>Accueil
                            </button>
                            <button className={activeTab === 'coodent' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('coodent')}>Découvrez COODE-NT
                            </button>
                            <button className={activeTab === 'nous-rejoindre' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('nous-rejoindre')}>Nous rejoindre
                                {
                                    candidatures && candidatures.length > 0 ? (
                                        <span className="contact-badge">{candidatures.filter(c => c.repondu === false).length}</span>
                                    ) : null
                                }
                            </button>
                            <button className={activeTab === 'contact' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('contact')}>Contact
                                {
                                    contacts && contacts.length > 0 ? (
                                        <span className="contact-badge">{contacts.filter(c => c.repondu === false).length}</span>
                                    ) : null
                                }
                            </button>
                        </div>
                        <div className="tab-content">
                            {activeTab === 'accueil' && renderAccueil()}
                            {activeTab === 'coodent' && renderCoodent()}
                            {activeTab === 'nous-rejoindre' && renderNousRejoindre()}
                            {activeTab === 'contact' && renderContact()}
                        </div>
                    </div>
                )
            }
            <Modale
                isOpen={modaleState.isOpen}
                onClose={handleCloseModale}
                title={modaleState.type === 'success' ? 'Succès' : 'Erreur'}
                type={modaleState.type} // Passez le type ici
            >
                <p>{modaleState.message}</p>
            </Modale>
        </div>
    );
}

export default Administration