import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useSelector } from 'react-redux';
import { Container } from "react-bootstrap";

import './App.css';

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Accueil from "./screens/Accueil/Accueil";
import Collaborateurs from "./screens/Collaborateurs/Collaborateurs";
import Valeurs from "./screens/Valeurs/Valeurs";
import Partenaires from "./screens/Partenaires/Partenaires";
import DomiSimpli from "./screens/Projets/DomiSimpli";
import Services from "./screens/Projets/Services";
import OffresEmplois from "./screens/OffresEmplois/OffresEmplois";
import Candidature from "./screens/Candidature/Candidature";
import Contact from "./screens/Contact/Contact";
import Connexion from "./screens/Connexion/Connexion";
import Administration from "./screens/Administration/Administration";
import PageNotFound from "./screens/PageNotFound/PageNotFound";

import axios from 'axios';
import { Component } from "react";

function App() {
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });


  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  return (
    <Router>
      <main>
        <Header />
        {userInfo ?
          <Routes>
            <Route path="/" element={<Accueil />} />
              <Route path="/accueil" element={<Accueil />} />
              <Route path="/collaborateurs" element={<Collaborateurs />} />
              <Route path="/nos-valeurs" element={<Valeurs />} />
              <Route path="/nos-partenaires" element={<Partenaires />} />
              <Route path="/domisimpli" element={<DomiSimpli />} />
              <Route path="/services" element={<Services />} />
              <Route path="/offres-emploi" element={<OffresEmplois />} />
              <Route path="/candidature" element={<Candidature />} />
              <Route path="/contact" element={<Contact />} />

            <Route path="/administration" element={<Administration />} />

            <Route path="*" element={<PageNotFound />} />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
          :
          <Container className="children"> {/* Dans SideBar */}
            <Routes>
              {/* Routes Administrateur */}
              <Route path="/" element={<Accueil />} />
              <Route path="/accueil" element={<Accueil />} />
              <Route path="/collaborateurs" element={<Collaborateurs />} />
              <Route path="/nos-valeurs" element={<Valeurs />} />
              <Route path="/nos-partenaires" element={<Partenaires />} />
              <Route path="/domisimpli" element={<DomiSimpli />} />
              <Route path="/services" element={<Services />} />
              <Route path="/offres-emploi" element={<OffresEmplois />} />
              <Route path="/candidature" element={<Candidature />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/connexion" element={<Connexion />} />
              {/* Routes Visiteur */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Container>
        }
        <Footer />
      </main>
    </Router>
  );
}

export default App;
