import { combineReducers } from 'redux';

import {
    contactListReducer,
    contactAddReducer,
    contactDeleteReducer,
    contactUpdateReducer
} from './contactReducers';

import {
    informationListReducer,
    informationAddReducer,
    informationDeleteReducer,
    informationUpdateReducer
} from './informationReducers';

import {
    partenaireListReducer,
    partenaireAddReducer,
    partenaireDeleteReducer,
    partenaireUpdateReducer
} from './partenaireReducers';

import {
    candidatureListReducer,
    candidatureAddReducer,
    candidatureDeleteReducer,
    candidatureUpdateReducer
} from './candidatureReducers';

import {
    offreEmploiListReducer,
    offreEmploiAddReducer,
    offreEmploiDeleteReducer,
    offreEmploiUpdateReducer
} from './offreEmploiReducers';

import {
    projetListReducer,
    projetAddReducer,
    projetDeleteReducer,
    projetUpdateReducer
} from './projetReducers';

import userReducer from '../../features/user/userSlice';


const appReducer = combineReducers({
    contactList: contactListReducer,
    contactAdd: contactAddReducer,
    contactDelete: contactDeleteReducer,
    contactUpdate: contactUpdateReducer,

    informationList: informationListReducer,
    informationAdd: informationAddReducer,
    informationDelete: informationDeleteReducer,
    informationUpdate: informationUpdateReducer,

    partenaireList: partenaireListReducer,
    partenaireAdd: partenaireAddReducer,
    partenaireDelete: partenaireDeleteReducer,
    partenaireUpdate: partenaireUpdateReducer,

    candidatureList: candidatureListReducer,
    candidatureAdd: candidatureAddReducer,
    candidatureDelete: candidatureDeleteReducer,
    candidatureUpdate: candidatureUpdateReducer,

    offreEmploiList: offreEmploiListReducer,
    offreEmploiAdd: offreEmploiAddReducer,
    offreEmploiDelete: offreEmploiDeleteReducer,
    offreEmploiUpdate: offreEmploiUpdateReducer,

    projetList: projetListReducer,
    projetAdd: projetAddReducer,
    projetDelete: projetDeleteReducer,
    projetUpdate: projetUpdateReducer,

    userLogin: userReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
    }
    return appReducer(state, action);
};