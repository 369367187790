import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown, Col, Row, ListGroup, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { partenaireListAction } from '../../redux/actions/partenaireActions';

import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../../redux/actions/userActions'

import logo from '../../assets/coode-nt.png'
import './Header.css'

function Header() {
  const location = useLocation();
  const dispatch = useDispatch()
  let navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const partenaireList = useSelector((state) => state.partenaireList);
  const { partenaires, loading, error } = partenaireList;

  const [activeMenu, setActiveMenu] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(false);

  useEffect(() => {
    dispatch(partenaireListAction());
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const menuParam = queryParams.get('menu');
    const submenuParam = queryParams.get('submenu');

    setActiveMenu(menuParam === 'open' || submenuParam === 'open');
    setActiveSubMenu(submenuParam === 'open');
  }, [location]);


  const handleNavDropdownToggle = (e) => {
    // Empêche le comportement par défaut sur les appareils non tactiles pour éviter les conflits
    if (!('ontouchstart' in window)) {
      e.preventDefault();
    }
    toggleSubMenu();
  };

  // Utilisez onMouseEnter uniquement pour les appareils non tactiles
  const handleMouseEnter = () => {
    if (!('ontouchstart' in window)) {
      setActiveMenu(true);
      setActiveSubMenu(true);
    }
  };

  // Utilisez onMouseLeave uniquement pour les appareils non tactiles
  const handleMouseLeave = () => {
    if (!('ontouchstart' in window)) {
      setActiveSubMenu(false);
      setTimeout(() => {
        if (!activeSubMenu) {
          setActiveMenu(false);
        }
      }, 200);
    }
  };


  const logoutHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  // Fonction pour basculer l'affichage du sous-menu
  const toggleSubMenu = () => {
    setActiveSubMenu(!activeSubMenu);
  };


  return (
    <>
      <header className={`header`}>
        <img
          src={logo} // Assurez-vous que le chemin est correct
          className="header-logo"
          alt="Logo"
        />
        <Navbar className='navbar' variant="dark">
          <Col xs={12} sm={12} md={12} lg={6} className="header-links-col">
            <Container className='header-pages-link'>
              <LinkContainer className='header-page-link' to='/accueil'>
                <Nav.Link className="nav-item">{t('Accueil')}</Nav.Link>
              </LinkContainer>

              {/* NavDropdown avec LinkContainer pour Découvrez COODE-NT */}
              <NavDropdown
                title={t('Découvrez COODE-NT')}
                id="basic-nav-dropdown"
                className="header-page-link nav-dropdown-hover"
              >
                <div className="first-level-item">
                  <LinkContainer to="/collaborateurs">
                    <NavDropdown.Item className="nav-subitem">{t('Qui sommes nous ?')}</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/nos-valeurs">
                    <NavDropdown.Item className="nav-subitem">{t('Nos valeurs')}</NavDropdown.Item>
                  </LinkContainer>
                  {
                    partenaires && partenaires.length > 0 && (
                      <LinkContainer to="/nos-partenaires">
                        <NavDropdown.Item className="nav-subitem">{t('Nos partenaires')}</NavDropdown.Item>
                      </LinkContainer>
                    )
                  }

                  <LinkContainer
                    to="/domisimpli"
                    onClick={handleNavDropdownToggle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <NavDropdown.Item className="nav-subitem">
                      {t('Nos projets')}
                      {
                        activeSubMenu && (
                          <div className="second-level-dropdown">
                            <LinkContainer to="/domisimpli">
                              <NavDropdown.Item className="nav-subitem">{t('DomiSimpli')}</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/services">
                              <NavDropdown.Item className="nav-subitem">{t('Services aux entreprises')}</NavDropdown.Item>
                            </LinkContainer>
                          </div>
                        )
                      }
                    </NavDropdown.Item>
                  </LinkContainer>
                </div>
              </NavDropdown>

              <LinkContainer className='header-page-link' to='/offres-emploi'>
                <Nav.Link className="nav-item">{t('Nous rejoindre')}</Nav.Link>
              </LinkContainer>
              <LinkContainer className='header-page-link' to='/contact'>
                <Nav.Link className="nav-item">{t('Contact')}</Nav.Link>
              </LinkContainer>
              {
                userInfo && userInfo.isAdmin && (
                  <LinkContainer className='header-page-link' to="/administration">
                    <Nav.Link className="nav-item">{t('Administration')}</Nav.Link>
                  </LinkContainer>
                )
              }
                {
                  userInfo && userInfo != undefined && userInfo.isAdmin ? (
                    <Nav.Link onClick={logoutHandler} className="nav-item">
                      <FontAwesomeIcon icon={faSignOutAlt} /> {/* Icône de déconnexion */}
                    </Nav.Link>
                  ) : (
                    <LinkContainer to="/connexion">
                      <Nav.Link className="nav-item">
                        <FontAwesomeIcon icon={faSignInAlt} /> {/* Icône de connexion */}
                      </Nav.Link>
                    </LinkContainer>
                  )
                }
            </Container>
          </Col>
        </Navbar>
      </header >
    </>
  )
}

export default Header