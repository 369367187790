import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './Candidature.css'; // Assurez-vous d'avoir le bon chemin pour votre fichier CSS

import Modale from '../../components/Modale/Modale';

import { candidatureAddAction, candidatureAddResetAction } from '../../redux/actions/candidatureActions';

function Candidature() {
    const dispatch = useDispatch();
    const location = useLocation()

    const { spontane, titre } = location.state || { spontane: false, titre: '' }; // Fournissez une valeur par défaut

    const [modaleState, setModaleState] = useState({ isOpen: false, type: '', message: '' });

    const candidatureAdd = useSelector((state) => state.candidatureAdd);
    const { loading, error, success, candidature } = candidatureAdd;

    let successMessage = 'Votre candidature a été soumise avec succès! Nous vous contacterons sous peu.';
    let errorMessage = 'Une erreur s\'est produite lors de la soumission de votre candidature. Veuillez réessayer.';

    const [formData, setFormData] = useState({
        civilite: '',
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        texte: '',
        type_poste: titre,
        document: null,
    });

    useEffect(() => {
        console.log('spontane', spontane);
    }, [spontane]);

    useEffect(() => {
        if (success) {
            // Afficher une modale de succès
            setModaleState({ isOpen: true, type: 'add-success', message: successMessage });
        }
        else if (error) {
            // Afficher une modale d'erreur
            setModaleState({ isOpen: true, type: 'add-error', message: errorMessage });
        }
        dispatch(candidatureAddResetAction());
    }, [success, error]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            document: file,
        });
    };

    const handleSubmit = (e) => {
        console.log('handleSubmit');
        e.preventDefault();

        // Vous pouvez envoyer les données du formulaire vers votre API ou effectuer une action nécessaire ici
        console.log(formData);
        dispatch(candidatureAddAction(formData));

        //Réinitialiser le formulaire après la soumission
        setFormData({
            civilite: '',
            nom: '',
            prenom: '',
            email: '',
            telephone: '',
            texte: '',
            type_poste: '',
            cv: null,
        });

    };

    const handleCloseModale = () => {
        setModaleState({ ...modaleState, isOpen: false });
    };

    return (
        <div className="candidature-container">
            <Helmet>
                <title>Rejoignez COODE-NT pour travailler sur des projets passionnants</title>
                <meta name="description" content="Candidature" />
                <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store" />
            </Helmet>
            <h2>Formulaire de Candidature</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Civilité</label>
                    <select name="civilite" value={formData.civilite} onChange={handleChange} required>
                        <option value="">-- Sélectionnez --</option>
                        <option value="Monsieur">Monsieur</option>
                        <option value="Madame">Madame</option>
                    </select>
                </div>
                {
                    spontane && (
                        <div className="form-group">
                            <label>Type de poste</label>
                            <select name="type_poste" value={formData.type_poste} onChange={handleChange} required>
                                <option value="">-- Sélectionnez --</option>
                                <option value="Admin systèmes et réseaux">Admin systèmes et réseaux</option>
                                <option value="Développeur">Développeur</option>
                                <option value="Chef de projet">Chef de projet</option>
                                <option value="Community manager">Community manager</option>
                                <option value="Administratif">Administratif</option>
                                <option value="Comptable">Comptable</option>
                                <option value="Autre">Autre</option>
                            </select>
                        </div>
                    )
                }
                <div className="form-group">
                    <label>Nom</label>
                    <input type="text" name="nom" value={formData.nom} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Prénom</label>
                    <input type="text" name="prenom" value={formData.prenom} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Numéro de Téléphone</label>
                    <input type="tel" name="telephone" value={formData.telephone} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Parlez nous de vous...</label>
                    <textarea name="texte" value={formData.texte} onChange={handleChange} rows="4" required></textarea>
                </div>
                <div className="form-group">
                    <label>CV (Fichier)</label>
                    <input type="file" accept=".pdf,.doc,.docx" name="cv" onChange={handleFileChange} />
                </div>
                <button type="submit">Envoyer</button>
            </form>
            <Modale
                isOpen={modaleState.isOpen}
                onClose={handleCloseModale}
                title={modaleState.type === 'add-success' ? 'Succès' : 'Erreur'}
                type={modaleState.type} // Passez le type ici
            >
                <p>{modaleState.message}</p>
            </Modale>
        </div>
    );
}

export default Candidature;
