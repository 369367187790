import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import { projetListAction } from '../../redux/actions/projetActions';
import './Projets.css'; // Assurez-vous d'avoir le bon chemin pour votre fichier CSS

import { createMarkup } from '../../utils';

function Services() {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const projetList = useSelector((state) => state.projetList);
    const { projets, loading, error } = projetList;

    useEffect(() => {
        dispatch(projetListAction());
    }, [dispatch]);

    return (
        <div className="projets-container">
            <Helmet>
                <title>Découvrez nos services pour les entreprises entreprises</title>
                <meta name="description" content="Services" />
                <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store" />
            </Helmet>
            {loading && <p>Chargement...</p>}
            {error && <p>Erreur : {error}</p>}
            {projets && projets
                .filter(projet => projet.titre === "Nos Services de Développement Informatique pour Entreprises")
                .map(projet => (
                    <>
                        <div className="projet-titre">
                            <img src={projet.image} className="projet-image" />
                            <div className="projet-media">
                                <div className="app-links">
                                    {projet.url_google_app && (
                                        <a href={projet.url_google_app} target="_blank" rel="noopener noreferrer">
                                            <img src="http://localhost:3000/media/images/store/google-play.png" alt="Disponible sur Google Play" />
                                        </a>
                                    )}
                                    {projet.url_apple_app && (
                                        <a href={projet.url_apple_app} target="_blank" rel="noopener noreferrer">
                                            <img src="http://localhost:3000/media/images/store/apple-store.png" alt="Disponible sur l'App Store" />
                                        </a>
                                    )}
                                    {
                                        !projet.url_google_app && !projet.url_apple_app && (
                                            <h3>{projet.titre}</h3>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                        <div key={projet.id} className="projet-card">

                            <div className="projet-info">
                                {projet.url_google_app && projet.url_apple_app && projet.url_site_web && (
                                    <a href={projet.url_site_web} target="_blank" rel="noopener noreferrer" className="btn-visiter">Visiter le site</a>
                                )}
                                <p className='information-description' dangerouslySetInnerHTML={createMarkup(projet.description)}></p>
                            </div>
                            <div className="services-btn-container">
                                <button className="service-btn" onClick={() => navigate('/contact')}>
                                    Nous contacter
                                </button>
                            </div>
                        </div>
                    </>
                ))}
        </div>
    );
}

export default Services;
