import {
    CANDIDATURE_LIST_REQUEST,
    CANDIDATURE_LIST_SUCCESS,
    CANDIDATURE_LIST_FAIL,

    CANDIDATURE_ADD_REQUEST,
    CANDIDATURE_ADD_SUCCESS,
    CANDIDATURE_ADD_FAIL,
    CANDIDATURE_ADD_RESET,

    CANDIDATURE_DELETE_REQUEST,
    CANDIDATURE_DELETE_SUCCESS,
    CANDIDATURE_DELETE_FAIL,
    CANDIDATURE_DELETE_RESET,

    CANDIDATURE_UPDATE_REQUEST,
    CANDIDATURE_UPDATE_SUCCESS,
    CANDIDATURE_UPDATE_FAIL,
    CANDIDATURE_UPDATE_RESET
} from '../constants/candidatureConstants';

import axios from 'axios';

export const candidatureListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CANDIDATURE_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/candidature/get_candidatures/`,
            config
        )

        dispatch({
            type: CANDIDATURE_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CANDIDATURE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const candidatureAddAction = (candidature) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CANDIDATURE_ADD_REQUEST,
        })

        let formData = new FormData();
        // Ajoutez chaque champ de l'objet candidature au formData
        for (const key in candidature) {
            if (candidature.hasOwnProperty(key)) {
                formData.append(key, candidature[key]);
            }
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await axios.post(
            `/api/candidature/add_candidature/`,
            formData,
            config
        )

        dispatch({
            type: CANDIDATURE_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CANDIDATURE_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const candidatureDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CANDIDATURE_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/candidature/delete_candidature/${id}/`,
            config
        )

        dispatch({
            type: CANDIDATURE_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CANDIDATURE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const candidatureUpdateAction = (candidature_id, candidature) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CANDIDATURE_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/candidature/update_candidature/${candidature_id}/`,
            candidature,
            config
        )

        dispatch({
            type: CANDIDATURE_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CANDIDATURE_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const candidatureAddResetAction = () => (dispatch) => {
    dispatch({ type: CANDIDATURE_ADD_RESET });
}

export const candidatureDeleteResetAction = () => (dispatch) => {
    dispatch({ type: CANDIDATURE_DELETE_RESET });
}

export const candidatureUpdateResetAction = () => (dispatch) => {
    dispatch({ type: CANDIDATURE_UPDATE_RESET });
}