import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { informationListAction } from '../../redux/actions/informationActions';
import './Accueil.css'; // Assurez-vous d'importer votre fichier CSS

import { createMarkup } from '../../utils';

function Accueil() {
  const dispatch = useDispatch();

  const informationList = useSelector((state) => state.informationList);
  const { informations, loading, error } = informationList;

  useEffect(() => {
    dispatch(informationListAction());
  }, [dispatch]);

  return (
    <div className="informations-container">
      <Helmet>
        <title>Nos Services de développement informatique chez COODE-NT</title>
        <meta name="description" content="Accueil" />
        <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store" />
      </Helmet>
      {loading && <p>Chargement...</p>}
      {error && <p>Erreur : {error}</p>}
      {informations && informations.length > 0 && informations
        .filter((information) => information.type === "Accueil")
        .map((information) => (
          <div key={information._id} className="information-card">
            <img src={information.image} className="information-image" />
            <div className="information-text">
              <h1>{information.titre}</h1>
              {/* Utilisation de dangerouslySetInnerHTML pour injecter le HTML */}
              <p className='information-description' dangerouslySetInnerHTML={createMarkup(information.description)}></p>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Accueil;
